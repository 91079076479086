import React from "react"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"

import Layout from "../../components/layout"
import style from "./index.module.css"
import AndvicHeader from "../../components/andvic-header"
import BurgerMenu from "../../components/burger-menu"
import SEO from "../../components/seo"

const BackgroundSection = ({
  data,
  className,
}: {
  data: any
  className: any
}) => {
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <>
      <BackgroundImage Tag="section" className={className} fluid={imageData}>
        <AndvicHeader></AndvicHeader>
      </BackgroundImage>
    </>
  )
}

export default function IndexPage(): JSX.Element {
  const data = useStaticQuery(graphql`
    query {
      andrew: file(relativePath: { eq: "andrew.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      victor: file(relativePath: { eq: "victor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage: file(relativePath: { eq: "kitchen-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktop: file(relativePath: { eq: "pool.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About" />
      <BurgerMenu></BurgerMenu>

      <BackgroundSection
        data={data}
        className={style.header}
      ></BackgroundSection>
      <div className={style.mainHeader}>
        <div>
          <h1 className={style.header1}>About Us</h1>
        </div>
      </div>
      <div className={style.copy}>
        <div>
          <h2>Why partner with Andvic?</h2>
          <h3>Customer Service</h3>
          <p>
            Our management team prides itself not only in the delivery of the
            final product but takes our clients on the journey from start to
            finish by keeping them informed, up to date and providing responsive
            engagement throughout the whole development
          </p>
          <h3>Experience / Expertise / Capability</h3>
          <p>
            All our projects are overseen by our Directors Andrew Shearston and
            Victor Yip to ensure the above factors are given constant attention
            and our qualities that have made us successful are reflected in the
            final product and with satisfied clients. Their previous combined
            industry experience of over 25 years with well recognised builders
            in both the residential and commercial sectors provides diverse
            expertise and capability to deliver your next project
          </p>
          <h3>Best Practices / Social Responsibility</h3>
          <p>
            The Andvic team are conscious of our potential impacts to the
            surrounding community / neighbours and we commit ourselves to
            constantly improving safe work and environmentally responsible
            practices while ensuring the project is delivered to a high standard
            while remaining on time / budget
          </p>
          <h2>Directors</h2>

          <div className={style.profile}>
            <div className={style.profileImage}>
              <Img fluid={data.andrew.childImageSharp.fluid} />
            </div>
            <div className={style.profileDescription}>
              <h3 className={style.profileHeader}>Andrew Shearston</h3>
              <p>
                Andrew is experienced in the project management and delivery of
                high-quality commercial projects and luxury residential homes.
                His professionalism and high attention to detail allows
                effective collaboration with clients and consultants to add
                value to the project design and enhance constructability onsite.
              </p>
              <p>
                He maintains a high standard for the final product and with over
                15 years of carpentry / site management experience, ensures
                works are co-ordinated with other trade subcontractors and
                issues onsite are resolved promptly to minimise delays to the
                construction program.
              </p>
            </div>
          </div>
          <div className={classNames(style.profile, style.profileVictor)}>
            <div className={style.profileDescription}>
              <h3 className={style.profileHeader}>Victor Yip</h3>
              <p>
                Victor holds a Bachelor’s degree in Civil Engineering
                (Construction), Business (Finance) and Diploma in Engineering
                Practice. Having completed his construction cadetship with
                Multiplex Construction Sydney, Victor performed as a Site
                Engineer in the delivery of projects onsite and later held a
                Cost Planning / Estimating position in their New Business Team.
                He has over ten years of construction and design management
                experience from delivering project onsite and in preparing
                feasibility studies, cost / value management and financial
                management of Tier 1 commercial projects.
              </p>
              <p>
                Following on from his time at Multiplex, Victor performed a
                technical advisory role as an Associate Executive at Macquarie
                Capital Infrastructure Team. His expertise in construction
                project budgeting, cost analysis and whole of life building
                maintenance costs assisted with providing advisory services for
                the acquisitions of social infrastructure projects and assets.
              </p>
            </div>
            <div className={style.profileImage}>
              <Img fluid={data.victor.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
